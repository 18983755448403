<template>
  <div class="custom-layout-container">
    <div class="custom-layout-side">
      <div class="custom-layout-side-content">

        <v-list-item class="py-2">
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex align-center">
                <div class="text-h5">
                  {{ headline }}
                </div>
                <v-spacer></v-spacer>
                <div v-if="syncStatus">
                  <v-icon :color="syncIconColor" :small="syncIconSmall">{{ syncIcon }}</v-icon>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list>

          <!-- create item -->
          <v-list-item class="mb-2" link v-on:click="createItem">
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ createItemText }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- list items -->
          <v-list-item-group v-model="selectedItemIndex" color="primary">
            <v-list-item
                v-for="item in items"
                :key="item.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="item.name === ''"><em>&lt;neu&gt;</em></span>
                  <span v-else>{{ item.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

        </v-list>

      </div>
      <div class="custom-layout-side-border"></div>
    </div>
    <div class="custom-layout-main">

      <div class="top-bar d-flex align-center">
        <div class="flex-grow-1 fill-height px-2 pt-2 pb-1">

          <div v-for="(item, index) in items" :key="item.id" v-if="index === selectedItemIndex" class="fill-height">
            <v-sheet class="fill-height" elevation="1" rounded>
              <div class="d-flex align-center fill-height">
                <div class="text-h5 ml-4">
                  <span v-if="item.name === ''" class="grey--text2"><em>&lt;neu&gt;</em></span>
                  <span v-else>{{ item.name }}</span>
                </div>
                <v-spacer></v-spacer>
                <slot name="topBar"></slot>
                <v-btn class="mr-3 ml-2" icon @click.stop="dialogOpen = true">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </div>
            </v-sheet>
          </div>

        </div>
      </div>

      <!-- create a main area for each item -->
      <div v-for="(item, index) in items" :key="item.id"
           v-if="index === selectedItemIndex">

        <!-- main content -->
        <div class="px-2 pt-1 pb-2">
          <slot :item="item"></slot>
        </div>
        <!-- /main content -->

        <!-- options dialog -->
        <v-dialog v-model="dialogOpen" max-width="600px">
          <v-card>
            <v-card-title>Optionen</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-4">

              <div v-if="!editingName" class="d-flex align-baseline">
                <span class="mr-1">Bezeichnung:</span>
                <span v-if="item.name" class="black--text text-body-1">{{ item.name }}</span>
                <span v-else class="black--text text-body-1"><em>&lt;neu&gt;</em></span>
                <v-spacer></v-spacer>
                <v-btn outlined small @click="startEditingName(item.name)">
                  <v-icon left>mdi-pencil</v-icon>
                  Bearbeiten
                </v-btn>
              </div>

              <div v-else class="d-flex align-center">
                <v-text-field filled dense hide-details="auto" class="mr-2" v-model="newName"></v-text-field>
                <v-btn icon outlined color="green" small @click="saveName(item)">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </div>

              <v-btn class="mt-5" outlined color="red" @click="deleteItem(item.id)">
                <v-icon left>mdi-delete</v-icon>
                Löschen
              </v-btn>

            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- /options dialog -->

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "EditorFrame",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    headline: {
      type: String,
      default: "Items"
    },
    createItemText: {
      type: String,
      default: "Create New"
    },
    onItemSelected: null,
    syncStatus: null
  },
  data() {
    return {
      items: [],
      selectedItemIndex: -1,
      dialogOpen: false,
      editingName: false,
      newName: ""
    };
  },
  computed: {
    formCountText() {
      return "(" + this.items.length + ")";
    },
    syncIcon() {
      switch (this.syncStatus) {
        case "done":
          return "mdi-cloud-check-outline";
        case "waiting":
          return "mdi-file-edit-outline";//"mdi-file-edit-outline";//"mdi-timer-sand";
        case "inProgress":
          return "mdi-cloud-sync-outline";
        case "error":
          return "mdi-cloud-alert";
        default:
          return "mdi-help";
      }
    },
    syncIconColor() {
      switch (this.syncStatus) {
        case "done":
          return "#8ad08d";
        case "waiting":
          return "#a8ce7a"; // "#dfd760";
        case "inProgress":
          return "#a8ce7a";
        case "error":
          return "error";
        default:
          return "error";
      }
    },
    syncIconSmall() {
      return this.syncStatus !== "error";
    }
  },
  watch: {
    selectedItemIndex(val) {
      this.stopNameEditing();
      // fetch items again to keep list up to date
      // should not cause unwanted behaviour
      this.fetchItems();

      if (val >= 0) {
        if (this.onItemSelected) {
          this.onItemSelected(this.items[val]);
        }
      }
    }
  },
  filters: {
    itemNameHtml: function (name) {
      return name === "" ? "<em>&lt;neu&gt;</em>" : name;
    }
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.$store.dispatch("getRequest", [this.endpoint, this.itemsFetchedCallback]);
    },
    itemsFetchedCallback(data) {
      this.items = data;
    },
    createItem() {
      this.$store.dispatch("postRequest", [this.endpoint, "", this.itemCreatedCallback]);
    },
    itemCreatedCallback(newItem) {
      this.items.push(newItem);
    },
    deleteItem(id) {
      this.dialogOpen = false;
      this.$store.dispatch("deleteRequest", [this.endpoint + "/" + id, this.itemsFetchedCallback]);
    },
    startEditingName(name) {
      this.newName = name;
      this.editingName = true;
    },
    saveName(item) {
      item.name = this.newName;
      this.stopNameEditing();
      this.$store.dispatch("putRequest", [this.endpoint, item]);
    },
    stopNameEditing() {
      this.editingName = false;
    }
  }
};
</script>

<style scoped>

.custom-layout-container {
  display: flex;
}

.custom-layout-side {
  height: 100vh;
  top: 64px;
  max-height: calc(100% - 64px);
  transform: translateX(0%);
  width: 256px;
  background-color: white;
  position: fixed;
}

.custom-layout-side-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-layout-side-border {
  background-color: rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}

.custom-layout-main {
  padding-left: 256px;
  flex-grow: 1;
}

.top-bar {
  height: 72px;
}

</style>