<template>
  <div>
    <edit-wrapper title="Textfeld" :component="component" :on-change="onChange" :show-quiz-toggle="false">
      <v-radio-group hide-details="auto" class="ma-0 pa-0" v-model="componentData['multiline']" @change="change">
        <v-radio label="Einzeilig"></v-radio>
        <v-radio label="Mehrzeilig"></v-radio>
      </v-radio-group>

      <v-text-field filled hide-details dense class="mt-3" label="Label" v-model="componentData['label']"
                    @input="change"></v-text-field>

      <div v-if="component['quiz']" class="mt-3">
        Korrekte Antwortmöglichkeiten (Elemente durch Semikolon ; trennen)
        <v-textarea filled rows="3" hide-details="auto" v-model="validation['correctAnswers']"
                    @input="change"></v-textarea>
      </div>
    </edit-wrapper>
  </div>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "TextFieldEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  computed: {
    componentData() {
      return this.component.data;
    },
    validation() {
      return this.component["validation"];
    }
  },
  methods: {
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>