<template>
  <div>
    <edit-wrapper title="Text" :component="component" :on-change="onChange" :show-quiz-toggle="false" :show-mandatory-toggle="false">
      <v-textarea filled placeholder="Inhalt" hide-details rows="3" v-model="component.data.content"
                  @input="onChange(component)"></v-textarea>
      <v-select class="mt-3" filled hide-details label="Formatierung" :items="styles" v-model="component.data.style"
                @input="onChange(component)"></v-select>
    </edit-wrapper>
  </div>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "text-edit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data: () => {
    return {
      styles: [
        { value: "none", text: "(ohne)" },
        { value: "task", text: "Aufgabenstellung" },
        { value: "default", text: "Standard-Text" }
      ]
    };
  }
};
</script>

<style scoped>

</style>