<template>
  <edit-wrapper title="Auswahlbox" :component="component" :on-change="onChange"
                :show-mandatory-toggle="true" :show-quiz-toggle="false">

    <v-text-field filled hide-details dense class="mb-3" label="Label" v-model="componentData['label']"
                  @input="change"></v-text-field>

    <span>Optionen:</span>
    <div>
      <div v-for="(option, index) in componentData['items']" :key="index" class="mt-2">
        <div class="d-flex align-center">

          <v-text-field v-model="componentData['items'][index]" hide-details dense filled class="mr-1"
                        :placeholder="'Option ' + (index + 1)" @input="change"></v-text-field>
          <v-btn color="red" text icon @click="removeOption(index)">
            <v-icon small>mdi-close</v-icon>
          </v-btn>

        </div>
      </div>
    </div>

    <v-btn class="mt-3" outlined small @click="addOption">
      Option hinzufügen
    </v-btn>

  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";
import Vue from "vue";

export default {
  name: "ChoiceBoxEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {
    componentData() {
      return this.component.data;
    },
    validation() {
      return this.component["validation"];
    }
  },
  methods: {
    change() {
      this.onChange(this.component);
    },
    removeOption(index) {
      this.componentData['items'].splice(index, 1);
      this.change();
    },
    addOption() {
      const data = this.componentData;
      if (!data.hasOwnProperty("items")) {
        Vue.set(data, "items", []);
      }
      data['items'].push("");
      this.change();
    }
  }
};
</script>

<style scoped>

</style>