<template>
  <edit-wrapper title="Dateiupload" :component="component" :on-change="onChange" :show-quiz-toggle="false">
    <div class="d-flex align-baseline text-body-1">
      <div>ID:</div>
      <div v-text="component.data.id" class="black--text ml-1"></div>
    </div>
  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";
import FileUpload from "@/components/FileUpload";

export default {
  name: "FileUploadEdit",
  components: { FileUpload, EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped>

</style>