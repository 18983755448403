<template>
  <edit-wrapper title="Link / Download" :component="component" :on-change="onChange" :show-quiz-toggle="false">
    <v-text-field hide-details label="Bezeichnung" v-model="component.data.name"
                  @input="onChange(component)"></v-text-field>
    <v-text-field hide-details label="URL" class="mt-3" v-model="component.data.href"
                  @input="onChange(component)"></v-text-field>
    <v-checkbox label="Download" class="my-0 py-0 mt-3" hide-details="auto" v-model="component.data['download']"
                @change="onChange(component)">
    </v-checkbox>
  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "LinkEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped>

</style>