<template>
  <edit-wrapper title="Datei-Generierung" :component="component" :on-change="onChange" :show-quiz-toggle="false" :show-mandatory-toggle="false">
    <div class="d-flex align-center">
      <div class="mr-2">Template-ID:</div>
      <v-text-field filled hide-details="auto" dense v-model="component.data['templateId']"
                    @input="onChange(component)"></v-text-field>
    </div>
    <div class="d-flex align-center mt-2">
      <div class="mr-2">Titel:</div>
      <v-text-field filled hide-details="auto" dense v-model="component.data['title']"
                    @input="onChange(component)"></v-text-field>
    </div>
  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "FileGenerationEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped>

</style>