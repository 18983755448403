<template>
  <div>
    <edit-wrapper title="Einfachauswahl" :component="component" :on-change="onChange">

      <span>Optionen:</span>

      <div>
        <div v-for="(option, index) in component.data.options" :key="index" class="mt-2">
          <div class="d-flex align-center">

            <!-- <div class="mr-2" style="white-space: nowrap">{{ (index + 1) + ": " }}</div> -->
            <v-radio-group v-if="component['quiz']" v-model="component['validation']['correctSelection']"
                           class="ma-0 pa-0" hide-details @change="change">
              <v-radio :value="index" color="green"></v-radio>
            </v-radio-group>
            <v-text-field v-model="option.text" hide-details dense filled class="mr-1"
                          :placeholder="'Option ' + (index + 1)" @input="change"></v-text-field>
            <v-btn color="red" text icon @click="removeOption(index)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>

          </div>
        </div>
      </div>

      <v-btn class="mt-3" outlined small @click="addOption">
        Option hinzufügen
      </v-btn>

    </edit-wrapper>
  </div>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";
import Vue from "vue";

export default {
  name: "RadioButtonsEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    addOption() {
      const data = this.component.data;
      if (!data.hasOwnProperty("options")) {
        Vue.set(data, "options", []);
      }
      data.options.push({ text: "" });
      this.change();
    },
    removeOption(index) {
      this.component.data.options.splice(index, 1);
      this.change();
    },
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>