<template>
  <edit-wrapper title="Mehrfachauswahl" :component="component" :on-change="onChange">

    <span>Optionen:</span>
    <div>
      <div v-for="(option, index) in component.data.options" :key="index" class="mt-2">
        <div class="d-flex align-center">

          <v-checkbox v-if="component['quiz']" v-model="component['validation']['correctSelections']" :value="index"
                      hide-details class="ma-0 pa-0" color="green" @change="change"></v-checkbox>

          <v-text-field v-model="option.text" hide-details dense filled class="mr-1"
                        :placeholder="'Option ' + (index + 1)" @input="change"></v-text-field>
          <v-btn color="red" text icon @click="removeOption(index)">
            <v-icon small>mdi-close</v-icon>
          </v-btn>

        </div>
      </div>
    </div>

    <v-btn class="mt-3" outlined small @click="addOption">
      Option hinzufügen
    </v-btn>

  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";
import Vue from "vue";

export default {
  name: "MultipleChoiceEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    addOption() {
      const data = this.component.data;
      if (!data.hasOwnProperty("options")) {
        Vue.set(data, "options", []);
      }
      data.options.push({ text: "" });
      this.change();
    },
    removeOption(index) {
      this.component.data.options.splice(index, 1);
      // remove correct selections that are now out of bounds to prevent unsolvable forms
      const length = this.component.data.options.length;
      this.component.validation["correctSelections"] = this.component.validation["correctSelections"].filter(index => index < length);
      this.change();
    },
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>