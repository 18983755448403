<template>
  <v-main>
    <EditorFrame endpoint="forms" headline="Formulare" create-item-text="Neues Formular"
                 :on-item-selected="itemSelected" :sync-status="syncStatus">

      <template v-slot:topBar>
        <v-btn outlined color="primary" small @click="previewMode = !previewMode">
          {{ modeSwitchButtonText }}
        </v-btn>
      </template>

      <template v-slot:default="slotProps">

        <div v-if="previewMode">
          <v-card shaped>
            <v-card-title class="justify-center2">
              <em class="grey--text">Vorschau</em>
              <v-spacer></v-spacer>
              <v-btn outlined color="red" small @click="deleteFilledOut(slotProps.item.id)">
                Meine Antworten löschen
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <Form v-if="!hideFormForReloading" :form-id="slotProps.item.id" reference="preview"></Form>
            </v-card-text>
          </v-card>

        </div>

        <div v-else>

          <!-- only display if loaded and add shortcut variable for item -->
          <div v-if="componentsByFormId[slotProps.item.id]" :set="item = slotProps.item">

            <!-- list of form components -->
            <transition-group name="component-list">
              <div v-for="component in componentsByFormId[item.id]" :key="component.id" class="mt-1 mb-3">

                <text-edit v-if="component.type === 1" :component="component" :on-change="componentChanged"></text-edit>
                <radio-buttons-edit v-else-if="component.type === 4" :component="component"
                                    :on-change="componentChanged"></radio-buttons-edit>
                <MultipleChoiceEdit v-else-if="component.type === 5" :component="component"
                                    :on-change="componentChanged"></MultipleChoiceEdit>
                <text-field-edit v-else-if="component.type === 7" :component="component"
                                 :on-change="componentChanged"></text-field-edit>
                <text-field-list-edit v-else-if="component.type === 8" :component="component"
                                      :on-change="componentChanged"></text-field-list-edit>
                <assign-edit v-else-if="component.type === 9" :component="component"
                             :on-change="componentChanged"></assign-edit>
                <ConfirmationEdit v-else-if="component.type === 10" :component="component"
                                  :on-change="componentChanged"></ConfirmationEdit>
                <file-upload-edit v-else-if="component.type === 11" :component="component"
                                  :on-change="componentChanged"></file-upload-edit>
                <link-edit v-else-if="component.type === 12" :component="component"
                           :on-change="componentChanged"></link-edit>
                <file-generation-edit v-else-if="component.type === 13" :component="component"
                                      :on-change="componentChanged"></file-generation-edit>
                <date-picker-edit v-else-if="component.type === 14" :component="component"
                                  :on-change="componentChanged"></date-picker-edit>
                <choice-box-edit v-else-if="component.type === 15" :component="component"
                                 :on-change="componentChanged"></choice-box-edit>
                <bmi-calculator-edit v-else-if="component.type === 16" :component="component"
                                     :on-change="componentChanged"></bmi-calculator-edit>
                <financial-goals-edit v-else-if="component.type === 17" :component="component"
                                      :on-change="componentChanged"></financial-goals-edit>
                <financial-goals-priority-edit v-else-if="component.type === 18" :component="component"
                                               :on-change="componentChanged"></financial-goals-priority-edit>

                <edit-wrapper v-else title="unbekannt" :component="component"
                              :on-change="componentChanged"></edit-wrapper>

              </div>
            </transition-group>
            <!-- /list of form components -->

            <!-- add component buttons -->
            <v-row class="mt-3" dense>
              <v-col class="flex-grow-0" v-for="type in formComponentTypes" :key="type.type">
                <v-btn class="text-none" color="white" @click="addFormComponent(slotProps.item.id, type.type)">
                  <v-icon left>mdi-plus</v-icon>
                  {{ type.label }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- /add component buttons -->

          </div>

        </div>

      </template>
    </EditorFrame>
  </v-main>
</template>

<script>
import EditorFrame from "@/components/EditorFrame";
import Vue from "vue";
import TextEdit from "@/components/form_components_edit/TextEdit";
import _ from "lodash";
import uniqueString from "unique-string";
import EditWrapper from "@/components/form_components_edit/EditWrapper";
import RadioButtonsEdit from "@/components/form_components_edit/RadioButtonsEdit";
import Form from "@/components/Form";
import TextFieldEdit from "@/components/form_components_edit/TextFieldEdit";
import AssignEdit from "@/components/form_components_edit/AssignEdit";
import MultipleChoiceEdit from "@/components/form_components_edit/MultipleChoiceEdit";
import ConfirmationEdit from "@/components/form_components_edit/ConfirmationEdit";
import FileUploadEdit from "@/components/form_components_edit/FileUploadEdit";
import LinkEdit from "@/components/form_components_edit/LinkEdit";
import FileGenerationEdit from "@/components/form_components_edit/FileGenerationEdit";
import TextFieldListEdit from "@/components/form_components_edit/TextFieldListEdit";
import DatePickerEdit from "@/components/form_components_edit/DatePickerEdit";
import ChoiceBoxEdit from "@/components/form_components_edit/ChoiceBoxEdit";
import BmiCalculatorEdit from "@/components/form_components_edit/BmiCalculatorEdit";
import FinancialGoalsEdit from "@/components/form_components_edit/FinancialGoalsEdit";
import FinancialGoalsPriorityEdit from "@/components/form_components_edit/FinancialGoalsPriorityEdit";

export default {
  name: "FormEditor",
  components: {
    FinancialGoalsPriorityEdit,
    FinancialGoalsEdit,
    BmiCalculatorEdit,
    ChoiceBoxEdit,
    DatePickerEdit,
    TextFieldListEdit,
    FileGenerationEdit,
    LinkEdit,
    FileUploadEdit,
    ConfirmationEdit,
    MultipleChoiceEdit,
    AssignEdit,
    TextFieldEdit,
    Form,
    RadioButtonsEdit,
    EditWrapper,
    TextEdit,
    EditorFrame
  },
  data: () => {
    return {
      formComponentTypes: [
        { type: "1", label: "Text" },
        /*{ type: "2", label: "Html" },
        { type: "3", label: "Container" },*/
        { type: "4", label: "Einfachauswahl" },
        { type: "5", label: "Mehrfachauswahl" },
        /*{ type: "6", label: "Drag-and-Drop" }*/
        { type: "7", label: "Textfeld" },
        { type: "8", label: "Textfeld-Liste" },
        { type: "9", label: "Paare Zuordnen" },
        { type: "10", label: "Bestätigung" },
        { type: "11", label: "Dateiupload" },
        { type: "12", label: "Link / Download" },
        { type: "13", label: "Datei-Generierung" },
        { type: "14", label: "Datumsauswahl" },
        { type: "15", label: "Auswahlbox" },
        { type: "16", label: "BMI-Rechner" },
        { type: "17", label: "Wirtschaftliche Ziele" },
        { type: "18", label: "Wirtschaftliche Ziele Priorisieren" }
      ],
      componentsByFormId: {},
      // use object instead of set because sets are not reactive
      componentsToPush: {},
      pendingRequests: {},
      errorOccurred: false,
      previewMode: false,
      hideFormForReloading: false
    };
  },
  computed: {
    syncStatus() {
      if (this.errorOccurred) {
        return "error";
      }
      if (Object.keys(this.componentsToPush).length > 0) {
        return "waiting";
      }
      if (Object.keys(this.pendingRequests).length > 0) {
        return "inProgress";
      }
      return "done";
    },
    modeSwitchButtonText() {
      return this.previewMode ? "Bearbeiten" : "Vorschau";
    }
  },
  methods: {
    itemSelected(item) {
      this.fetchFormComponents(item.id);
    },
    fetchFormComponents(id) {
      this.$store.dispatch("getRequest", ["forms/" + id + "/edit", this.fetchFormComponentsCallback]);
    },
    fetchFormComponentsCallback(data) {
      const formId = data.form.id;
      const formComponents = data["formComponents"];
      this.sortFormComponents(formComponents);
      Vue.set(this.componentsByFormId, formId, formComponents);
    },
    sortFormComponents(formComponents) {
      formComponents.sort((a, b) => a.position - b.position);
    },
    addFormComponent(formId, type) {
      this.$store.dispatch("postRequest", ["form-components/by-form/" + formId + "/" + type, "", this.addFormComponentCallback]);
    },
    addFormComponentCallback(data) {
      const formId = data["formId"];
      const list = this.componentsByFormId[formId];
      list.push(data);
    },
    componentChanged(componentUpdate) {
      if (componentUpdate.hasOwnProperty("action")) {
        if (componentUpdate["action"] === "delete") {
          this.deleteComponent(componentUpdate.id, componentUpdate.formId);
        }
        if (componentUpdate["action"] === "moveUp") {
          this.moveComponent(componentUpdate.id, componentUpdate.formId, true);
        }
        if (componentUpdate["action"] === "moveDown") {
          this.moveComponent(componentUpdate.id, componentUpdate.formId, false);
        }
      } else {
        Vue.set(this.componentsToPush, componentUpdate.id, componentUpdate);
        this.schedulePush();
      }
    },
    moveComponent(id, formId, moveUp) {
      const compList = this.componentsByFormId[formId];

      let element;
      let index;
      for (let i = 0; i < compList.length; i++) {
        if (compList[i].id === id) {
          element = compList[i];
          index = i;
          break;
        }
      }

      let otherElement = moveUp ? compList[index - 1] : compList[index + 1];
      if (otherElement) {
        const position = element.position;
        element.position = otherElement.position;
        otherElement.position = position;
      }

      this.sortFormComponents(compList);

      Vue.set(this.componentsToPush, element.id, element);
      Vue.set(this.componentsToPush, otherElement.id, otherElement);
      this.schedulePush();
    },
    deleteComponent(id, formId) {
      const compList = this.componentsByFormId[formId];
      for (let i = 0; i < compList.length; i++) {
        if (compList[i].id === id) {
          compList.splice(i, 1);
        }
      }
      this.$store.dispatch("deleteRequest", ["form-components/" + id]);
    },
    schedulePush: _.debounce(function() {
      this.pushComponents();
    }, 500),
    pushComponents() {
      let components = Object.values(this.componentsToPush);
      this.componentsToPush = {};
      let identifier = uniqueString();
      let requestPipelineEntry = {
        identifier: identifier,
        data: components,
        status: "pending"
      };
      Vue.set(this.pendingRequests, identifier, requestPipelineEntry);
      this.$store.dispatch("putRequest", ["form-components", components, this.pushComponentsCallback, identifier]);
    },
    pushComponentsCallback(data, identifier) {
      if (data !== null) {
        Vue.delete(this.pendingRequests, identifier);
      } else {
        this.errorOccurred = true;
        if (this.pendingRequests.hasOwnProperty(identifier)) {
          this.pendingRequests[identifier].status = "error";
        } else {
          Vue.set(this.pendingRequests, identifier, { status: "error" });
        }
      }

    },
    deleteFilledOut(formId) {
      this.hideFormForReloading = true;
      this.$store.dispatch("deleteRequest", ["forms/" + formId + "/preview/fill-out", this.deleteFilledOutCallback]);
    },
    deleteFilledOutCallback() {
      this.hideFormForReloading = false;
    }
  }
};
</script>

<style scoped>
.component-list-move {
  transition: transform 0.3s;
}

.component-list-enter-active,
.component-list-leave-active {
  transition: all 0.5s ease;
}

.component-list-enter,
.component-list-leave-to {
  opacity: 0;
}
</style>