<template>
  <edit-wrapper title="Paare Zuordnen" :component="component" :on-change="onChange" :lock-quiz-toggle="true">

    <v-row dense align="center" v-for="(pair, index) in component.data['pairs']" :key="index">
      <v-col>
        <v-text-field filled dense hide-details="auto" placeholder="Fragestellung" v-model="pair['slot']" @input="change"></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-icon>mdi-arrow-left</v-icon>
      </v-col>
      <v-col>
        <v-text-field filled dense hide-details="auto" placeholder="Zugehöriges Element" v-model="pair['element']" @input="change"></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-btn color="red" text icon @click="removeOption(index)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-btn class="mt-2" outlined small @click="addOption">
      Option hinzufügen
    </v-btn>

  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";
import Vue from "vue";

export default {
  name: "AssignEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    addOption() {
      const compData = this.component.data;
      if (!compData.hasOwnProperty("pairs")) {
        Vue.set(compData, "pairs", []);
      }
      compData["pairs"].push({ slot: "", element: "" });
      this.change();
    },
    removeOption(index) {
      this.component.data["pairs"].splice(index, 1);
      this.change();
    },
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>