<template>
  <edit-wrapper title="Textfeld-Liste" :component="component" :on-change="onChange" :show-quiz-toggle="false" :show-mandatory-toggle="true">

    <div class="d-flex align-center">
      <div class="mr-2">Anzahl Textfelder:</div>
      <v-text-field filled hide-details="auto" dense v-model="component.data['lineCount']"
                    @input="onChange(component)"></v-text-field>
    </div>

    <div class="mt-2">
      <v-switch hide-details class="ma-0" label="Anzahl sperren" v-model="component.data['lockLineCount']" @change="onChange(component)"></v-switch>
    </div>

  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "TextFieldListEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped>

</style>
