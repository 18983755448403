<template>
  <edit-wrapper title="Wirtschaftliche Ziele Priorisieren" :component="component" :on-change="onChange"
                :show-mandatory-toggle="true" :show-quiz-toggle="false">

    <div class="d-flex align-baseline">
      <div class="mr-2">Wirtschaftliche Ziele ID:</div>
      <v-text-field outlined dense hide-details="auto" type="number" v-model="component['data']['financialGoalsId']" @input="change"></v-text-field>
    </div>

  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "FinancialGoalsPriorityEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {
    componentData() {
      return this.component.data;
    },
    validation() {
      return this.component["validation"];
    }
  },
  methods: {
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>