<template>
  <edit-wrapper title="BMI Rechner" :component="component" :on-change="onChange"
                :show-mandatory-toggle="true" :show-quiz-toggle="false">
  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "BmiCalculatorEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {
    componentData() {
      return this.component.data;
    },
    validation() {
      return this.component["validation"];
    }
  },
  methods: {
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>