<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="d-flex flex-column" style="width: 100%">
          <div class="d-flex grow align-center">
            <span v-text="title"></span>
            <v-spacer></v-spacer>
            <div class="caption grey--text text--darken-2 mr-2" v-text="component.name"></div>
            <v-chip v-if="component['quiz'] && showQuizToggle" label small class="mr-2">Quiz</v-chip>
            <v-chip v-if="component['mandatory'] && showMandatoryToggle" label small class="mr-2">Pflicht</v-chip>
            <v-btn icon @click="moveComponent('moveUp')">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn class="mx-2" icon @click="moveComponent('moveDown')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon @click.stop="dialogOpen = true">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <div v-if="component['properties']['styleClasses']" class="caption grey--text text--darken-2 mr-4"
                 v-text="'Style-Klassen: ' + component['properties']['styleClasses']"></div>
            <div v-if="component['properties']['parameters']" class="caption grey--text text--darken-2 mr-42"
                 v-text="'Parameter: ' + component['properties']['parameters']"></div>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="false">{{ component }}</div>
        <slot></slot>
      </v-card-text>
    </v-card>

    <!-- options dialog -->
    <v-dialog v-model="dialogOpen" max-width="600px">
      <v-card>
        <v-card-title>Optionen</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt2-5">

          <div class="mt-5">
            <v-text-field outlined dense hide-details="auto" label="Name" v-model="component['name']"
                          @input="change"></v-text-field>
          </div>

          <v-switch v-if="showQuizToggle" v-model="component['quiz']" label="Quizfrage" hide-details inset
                    @change="change" :disabled="lockQuizToggle"></v-switch>

          <v-switch v-if="showMandatoryToggle" v-model="component['mandatory']" label="Pflichtfeld" hide-details inset
                    @change="change" :disabled="lockMandatory"></v-switch>

          <div class="mt-5">
            <v-text-field outlined dense hide-details="auto" label="Style-Klassen"
                          v-model="component['properties']['styleClasses']"
                          @input="change"></v-text-field>
            <v-text-field outlined dense hide-details="auto" label="Parameter"
                          v-model="component['properties']['parameters']" class="mt-3"
                          @input="change"></v-text-field>
          </div>

          <div class="d-flex mt-5">
            <!--<v-spacer></v-spacer>-->
            <v-btn outlined color="red" @click="deleteComponent">
              <v-icon left>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- /options dialog -->

  </div>
</template>

<script>
export default {
  name: "edit-wrapper",
  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    component: {
      type: Object,
      default: null,
      required: true
    },
    onChange: {
      type: Function,
      default: null,
      required: true
    },
    showQuizToggle: {
      type: Boolean,
      default: true
    },
    showMandatoryToggle: {
      type: Boolean,
      default: true
    },
    lockQuizToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogOpen: false
    };
  },
  computed: {
    lockMandatory() {
      return this.component["quiz"];
    }
  },
  methods: {
    change() {
      if (this.component["quiz"]) {
        this.component.mandatory = true;
      }
      this.onChange(this.component);
    },
    deleteComponent() {
      const deleteUpdate = {
        id: this.component.id,
        formId: this.component.formId,
        action: "delete"
      };
      this.onChange(deleteUpdate);
    },
    moveComponent(method) {
      const moveUpdate = {
        id: this.component.id,
        formId: this.component.formId,
        action: method
      };
      this.onChange(moveUpdate);
    }
  }
};
</script>

<style scoped>

</style>