<template>
  <edit-wrapper title="Datumsauswahl" :component="component" :on-change="onChange" :show-quiz-toggle="false"
                :show-mandatory-toggle="true">

    <v-text-field filled hide-details dense class="" label="Label" v-model="componentData['label']"
                  @input="change"></v-text-field>

  </edit-wrapper>
</template>

<script>
import EditWrapper from "@/components/form_components_edit/EditWrapper";

export default {
  name: "DatePickerEdit",
  components: { EditWrapper },
  props: ["component", "onChange"],
  data() {
    return {};
  },
  computed: {
    componentData() {
      return this.component.data;
    },
    validation() {
      return this.component["validation"];
    }
  },
  methods: {
    change() {
      this.onChange(this.component);
    }
  }
};
</script>

<style scoped>

</style>